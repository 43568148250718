import React from "react"
import { Container, Carousel } from "react-bootstrap"
import Image from "./image"
import {Link} from "gatsby"

import "../componentsStyles/bannerone.css"

const BanneronePage = props => (
  <Carousel indicators={false} controls={false}>
    <Carousel.Item>
      <Image
        className="w-100 fixedHeightPage imgPagina"
        filename="fotovoltaico-carousel"
        alt="NonsoloEnergia SRL"
      />
      <div className="fixedWidth">
        <Carousel.Caption className="">
          <h2 className="mb-5">
            <Link to="">Home</Link> > {props.title}
          </h2>
        </Carousel.Caption>
      </div>
    </Carousel.Item>
  </Carousel>
)

export default BanneronePage
