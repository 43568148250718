import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "gatsby"
import headerStyles from "../componentsStyles/header.module.scss"

import "../componentsStyles/footer.css"

const Footer = () => (
  <footer>
    <Container className="pl-1 pt-3">
      <Link to="/" className="footer-brand mb-2">
        <img
          className={`${headerStyles.NSE}`}
          src="../nonsoloenergiatitle.png"
          alt="Logo NonSoloEnergia SRL"
        />
      </Link>
      <Row>
        <Col className="mx-auto pl-5" md={4} sm={6}>
          <Link to="" className="footer-title">
            NONSOLOENERGIA
          </Link>
          <br />
          <Link to="azienda-e-partner">Azienda</Link>
          <br />
          <Link to="contatti" className="footer-title">
            CONTATTACI
          </Link>
          <Link to="fotovoltaico-residenziale">Impianto Fotovoltaico</Link>
          <Link to="ingegneria-energetica">Riqualificazione Energetica</Link>
          <Link to="certificazione-energetica">Certificazione</Link>
          <Link to="ingegneria-energetica">Ingegneria Energetica</Link>
          <Link to="cogenerazione">Cogenerazione</Link>
          <Link to="termografia">Termografia</Link>
          <Link to="mobilita-sostenibile">Mobilità Sostenibile</Link>
        </Col>
        <Col md={4} sm={6} className="pl-5 mx-auto justify-content-center">
          <div className="mx-auto">
            <Link to="richiesta-preventivo" className="footer-title">
              RICHIESTA PREVENTIVO
            </Link>
            <br />
            <Link to="contatti">Numero Verde</Link>
            <br />
            <br />
            <Link to="eternit-e-fotovoltaico">Eternit e Fotovoltaico</Link>
            <br />
            <Link to="solare-termodinamico">Solare Termodinamico</Link>
            <br />
            <Link to="fotovoltaico-con-accumulo">
              Fotovoltaico con accumulo
            </Link>
            <br />
            <Link to="alberghi-e-agriturismi">Alberghi e Agriturismi</Link>
            <br />
          </div>
        </Col>
        <Col md={4} sm={6} className="justify-content-center pt-3 pl-5 mx-auto">
          <Link to="lavora-con-noi" className="footer-title">
            LAVORA CON NOI
          </Link>
          <br />
          <Link to="privacy-policy">Privacy Policy</Link>
          <br />
          <br />
          <a
            href="https://www.facebook.com/Nonsoloenergia"
            role="button"
            rel="nofollow"
            className="float-right"
            target="_blank"
          >
            <span className="fa-stack fa-2x m-1">
              <i
                className={`${
                  headerStyles.fbBackground
                } fa fa-circle fa-stack-2x`}
              />
              <i
                className={`${
                  headerStyles.darkgreyColor
                } fa fa-facebook-f fa-stack-1x`}
              />
            </span>
          </a>
        </Col>
      </Row>
      <div className="text-right copyright">
        © {new Date().getFullYear()}, Creato con
        {` `}
        <a href="https://www.gatsbyjs.org" target="_blank" rel="nofollow">
          Gatsby
        </a>{" "}
        da{" "}
        <a href="https://www.fabiorunci.it" target="_blank" rel="nofollow">
          Fabio Runci
        </a>
      </div>
    </Container>
  </footer>
)
export default Footer
