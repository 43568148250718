/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import logo from "../../static/nselogo.png"

function SEO({ description, lang, meta, image, keywords, title, location }) {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "nselogo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed {
              src
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            image
            url
            author
          }
        }
      }
    `
  )

  const schemaOrgJSONLD = [
    {
      "@context": "https://schema.org",
      "@type": "HVACBusiness",
      name: "NonsoloEnergia SRL",
      image:
        "https://nse-gatsby.netlify.com/static/9aba7b4f21ed073b2499b7140adf0e43/7b526/NonsoloEnergiatitle.png",
      "@id": "",
      url: "https://www.nonsoloenergia.com",
      sameAs: ["https://www.facebook.com/Nonsoloenergia"],
      telephone: "06 9865300",
      address: {
        "@type": "PostalAddress",
        streetAddress: "via Rea Silvia",
        addressLocality: "Anzio",
        postalCode: "00042",
        addressCountry: "IT",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 41.4790402,
        longitude: 12.610439600000063,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "08:00",
        closes: "13:00",
      },
      hasOfferCatalog: {
        "@type": "OfferCatalog",
        name: "Servizi di Ingegneria Energetica",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Impianto Fotovoltaico",
            },
          },
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Riqualificazione Energetica",
            },
          },
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Rimozione eternit e impianto fotovoltaico",
            },
          },
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Termografia",
            },
          },
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Mobilità Sostenibile",
            },
          },
        ],
      },
    },
  ]

  const metaDescription = description || query.site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${query.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: `${query.site.siteMetadata.url}/${location}`,
        },
        {
          property: `og:image`,
          content: `${query.site.siteMetadata.url}${
            query.file.childImageSharp.fixed.src
          }`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: query.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `it`,
  meta: [],
  keywords: [
    "Impianti fotovoltaici Roma Anzio Nettuno Aprilia Ardea Pomezia Latina",
    "impianti fotovoltaici Lazio",
    "incentivi impianti fotovoltaici",
    "prezzi impianti fotovoltaici Anzio",
    "fotovoltaico Anzio",
    "impianti fotovoltaici Nettuno",
    "fotovoltaico Nettuno",
    "impianti fotovoltaici Roma",
    "verifica infiltrazioni dispersioni isolamento perdite umidità",
    "bici scooter elettrici",
    "incentivi scooter elettrici",
    "impianti a cogenerazione",
    "rifacimento coperture eternit",
    "impianti fotovoltaici",
    "impianti fotovoltaici Aprilia",
    "impianti fotovoltaici Ardea",
    "pannelli fotovoltaici Lazio",
    "impianti fotovoltaici costi",
    "APE Lazio",
    "APE Roma",
    "termografia Lazio",
    "attestato prestazione energetica Roma",
    "pannelli solari Lazio",
    "certificatore energetico Lazio",
    "costo 1kw fotovoltaico lazio",
    "rimozione smaltimento eternit lazio",
    "riqualificazione energetica edifici ROma",
    "impianti di climatizzazione Roma",
    "certificazione energetica",
    "pannelli fotovoltaici Roma",
    "pannelli solari Lazio",
    "pannelli solari Roma",
    "fotovoltaico Lazio",
    "pannelli fotovoltaici Lazio",
    "fotovoltaico",
    "fotovoltaici",
    "pannelli fotovoltaici",
    "prezzi impianti fotovoltaici",
    "offerta impianti fotovoltaici lazio",
    "costo impianti fotovoltaici Lazio",
  ],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
}

export default SEO
