import React from "react"
import { Container } from "react-bootstrap"

import "../componentsStyles/sezione.css"



const Sezione = props => (
  <Container className="sezione">
    <h4 className="text-center titoletto">
      {props.title}
      <hr className="separatore" />
    </h4>
    {props.children}
  </Container>
)

export default Sezione
