import React from "react"

import "../componentsStyles/pagina.css"

const CardPagina = props => (
  <div className={`cardPagina ${props.variante}  mt-2 text-justify`}>
    {props.children}
  </div>
)

export default CardPagina
