import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
import headerStyles from "../componentsStyles/header.module.scss"
import { Container, Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap"

import "../componentsStyles/header.css"

const servizi = [
  "Fotovoltaico Residenziale",
  "Fotovoltaico con accumulo",
  "Fotovoltaico Aziendale",
  "Alberghi e Agriturismi",
  "Solare Termodinamico",
  "Eternit e Fotovoltaico",
  "Cogenerazione",
  "Mobilità Sostenibile",
  "Certificazione Energetica",
  "Termografia",
  "Ingegneria Energetica",
  "Servizi-di-Ingegneria",
  "fuel-cells"
]

const contatti = [
  "Contatti",
  "Richiesta Preventivo",
  "Richiesta Informazioni",
  "Lavora con Noi"
]

const Header = ({ siteTitle }) => (
  <main className={headerStyles.silverColorBackground}>
    <div className={headerStyles.darkgreyColorBackground}>
      <Container>
        <Row className="align-items-center">
          <Col xs={8}>
            <h2 className={headerStyles.headerText}>
              Impianti fotovoltaici Lazio, Roma, Latina, Aprilia, Anzio,
              Nettuno, Ardea
            </h2>
          </Col>
          <Col xs={4} className="text-right">
            <a
              href="https://www.facebook.com/Nonsoloenergia"
              role="button"
              className=""
              role="facebook"
              rel="nofollow"
              target="_blank"
            >
              <span className="fa-stack fa-1x m-1">
                <i
                  className={`${
                    headerStyles.fbBackground
                  } fa fa-circle fa-stack-2x`}
                />
                <i
                  className={`${
                    headerStyles.darkgreyColor
                  } fa fa-facebook-f fa-stack-1x`}
                />
              </span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>

    <div className="container p-1">
      <Navbar className={headerStyles.gothic} bg="none" expand="lg">
        <Link className="navbar-brand" to="/">
          <img
            className={`${headerStyles.logo}`}
            src="../nonsoloenergia.png"
            alt="NonsoloEnergia SRL"
          />
          <img
            className={`${headerStyles.NSE}`}
            src="../nonsoloEnergiatitle.png"
            alt="NonsoloEnergia SRL"
          />
        </Link>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className="ml-auto">
            <Link className="nav-link nav-link-ltr" to="/">
              Home
            </Link>
            <Link
              activeClassName="attivo"
              className="nav-link nav-link-ltr"
              to="/azienda-e-partner"
            >
              Azienda
            </Link>
            <Link
              activeClassName="attivo"
              className="nav-link nav-link-ltr"
              to="/gallery"
            >
              Gallery
            </Link>
            <NavDropdown title="Servizi" id="dropdown" className="multiRow">
              {servizi.map(servizio => {
                return (
                  <Link
                    className="nav-link mr-2"
                    to={servizio
                      .split(" ")
                      .join("-")
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    {servizio}
                  </Link>
                )
              })}
            </NavDropdown>
            <NavDropdown title="Contatti" id="dropdown">
              {contatti.map(contatto => {
                return (
                  <Link
                    className="nav-link mr-2"
                    to={contatto
                      .split(" ")
                      .join("-")
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    {contatto}
                  </Link>
                )
              })}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </main>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
