/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../../node_modules/font-awesome/css/font-awesome.min.css"
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import layoutStyles from "../componentsStyles/layout.module.scss"

import Header from "./header"
import Footer from "./footer"
import LinksFooter from "../components/linksFooter"
import { Helmet } from "react-helmet"
import "../componentsStyles/layout.css"

const Layout = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <main className={`${layoutStyles.silverColorBackground}`}>
        <Helmet>
          <script>
            {`window.fbAsyncInit = function() {
              FB.init({
                xfbml            : true,
                version          : 'v3.3'
              });
            };

            (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/it_IT/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));`}
          </script>
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          className="fb-customerchat hiddenMobile"
          page_id="249059238483771"
          theme_color="#13cf13"
          logged_in_greeting="Benvenuto! NonsoloEnergia è a tua disposizione per qualsiasi necessità :)"
          logged_out_greeting="Benvenuto! NonsoloEnergia è a tua disposizione per qualsiasi necessità :)"
        />
        <div>
          <main>{props.children}</main>
          {props.isPagina && <LinksFooter />}
        </div>
        <Footer />
      </main>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
