import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

import "../componentsStyles/pagina.css"

const servizi = [
  "Azienda e Partner",
  "Ingegneria Energetica",
  "Gallery",
  "Richiesta Preventivo",
  "Fotovoltaico Residenziale",
  "Fotovoltaico con accumulo",
  "Fotovoltaico Aziendale",
  "Fuel Cells",
  "Alberghi e Agriturismi",
  "Solare Termodinamico",
  "Eternit e Fotovoltaico",
  "Cogenerazione",
  "Mobilità Sostenibile",
  "Certificazione Energetica",
  "Termografia",
  "Servizi di Ingegneria",
  "Lavora con noi",
  "Richiesta Informazioni",
  "Contatti",
]

const LinkFooter = () => (
  <div>
    <div className="text-center">
      {" "}
      <a href="tel:800913175">
        <img
          className="mx-auto numero-verde"
          src="../numeroVerde.jpg"
          alt="Numero Verde NonSoloEnergia SRL"
        />
      </a>
    </div>

    <Container className="footerPagina mt-5 mb-2">
      {servizi.map(servizio => {
        return (
          <Link
            to={servizio
              .split(" ")
              .join("-")
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}
          >
            {servizio} | &nbsp;
          </Link>
        )
      })}
    </Container>
  </div>
)

export default LinkFooter
